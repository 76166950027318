import momemt from 'moment';
import AOS from 'aos';

jQuery( document ).ready(function($) {

  // Animations
  AOS.init({
    duration: 1000,
    once: true,
  }
  );

  // EVENTS BIT
  // $.ajax( {
  //   url: 'https://rest.bandsintown.com/artists/Old%20Dominion/events?app_id=45PRESS_OLD_DOMINION',
  //   method: 'GET',
  //   dataType: 'json',
  //   error: () => {
  //     alert( 'Error fetching events!' );
  //   },
  //   success: data => {
  //   const events = $( '#events' );
  //   let html = '';
  //   if ( data.length ) {
  //     for ( let event of data ) {
  //       let location = event.venue.city + ', ' + event.venue.region;
  //       if ( location === ', ' ) {location = '';}
  //       if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
  //       html += `<div class="event id-${event.id}">`;
  //       html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
  //       html += '<div class="event-location">' + location + '</div>';
  //       html += '<div class="event-venue">' + event.venue.name + '</div>';
  //       html += '<div class="event-links">';
  //       for ( let offer of event.offers ) {
  //         html += '<a href="' + offer.url + '" target="_blank" class="btn btn-blue btn-big">' + offer.type + '</a>';
  //       }
  //       html += '</div>';
  //       html += '</div>';
  //     }
  //     events.html( html );
  //     } else {
  //       events.html( 'No upcoming events.' );
  //     }
  //   }
  // });
  
});